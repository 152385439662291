import { END_POINTS } from "../constants/url";
import {
  AddBannerRes,
  BannerResponse,
  CarPlateRes,
  ServiceFee,
  ServiceList,
  ServiceRequest,
} from "../types/General";
import emptySplitApi from "../utils/rtk";

type getServiceFeeRes = {
  message: string;
  statusCode: number;
  data: ServiceFee[];
};
type getServiceList = {
  message: string;
  statusCode: number;
  data: {
    data: ServiceList[];
  };
};

type serviceBody = {
  serviceType: string;
  serviceFees: string;
};

type GetTokenParams = {
  size: number;
  page: number;
  query: string;
  status?: number;
};

type ServiceRequestRes = {
  message: string;
  statusCode: number;
  data: {
    count: number;
    service: ServiceRequest[];
  };
};

type ServiceMgmtBody = {
  serviceId: string;
  status: number;
  agentId?: string;
};

type BannerRes = {
  message: string;
  statusCode: number;
  data: {
    count: number;
    banner: BannerResponse[];
  };
};

type CarPlateList = {
  data: CarPlateRes[];
  statusCode: 200;
  message: "Fetch successfully";
};

type AddBannerBody = {
  title: string;
  postId: string;
  image: string | undefined;
  startDate: string;
  endDate: string;
  role: string;
};

export const serviceApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getServiceFee: builder.query<getServiceFeeRes, {}>({
      query: () => ({
        url: END_POINTS.getServiceFee,
        method: "GET",
      }),
    }),
    changeServiceFeeStatus: builder.mutation<
      getServiceFeeRes,
      { service_id: string }
    >({
      query: ({ service_id }) => ({
        url: `${END_POINTS.service_fee_status}${service_id}/`,
        method: "PUT",
      }),
    }),
    deleteServiceById: builder.query<getServiceFeeRes, { service_id: string }>({
      query: ({ service_id }) => ({
        url: `${END_POINTS.delete_service}/${service_id}`,
        method: "DELETE",
      }),
    }),
    getServiceById: builder.query<
      {
        message: string;
        statusCode: number;
        data: ServiceFee[];
      },
      { service_id: string }
    >({
      query: ({ service_id }) => ({
        url: `${END_POINTS.getServiceFee}/${service_id}`,
        method: "GET",
      }),
    }),
    updateService: builder.mutation<
      { message: string; statusCode: number; data: ServiceFee },
      { service_id: string; body: serviceBody }
    >({
      query: ({ service_id, body }) => ({
        url: `${END_POINTS.edit_service}${service_id}/`,
        method: "PUT",
        body,
      }),
    }),

    getServiceList: builder.query<getServiceList, {}>({
      query: () => ({
        url: END_POINTS.getServices,
        method: "GET",
      }),
    }),
    getServiceListById: builder.query<
      {
        message: string;
        statusCode: number;
        data: ServiceList;
      },
      { service_id: string }
    >({
      query: ({ service_id }) => ({
        url: `${END_POINTS.getServices}/${service_id}`,
        method: "GET",
      }),
    }),
    addService: builder.mutation<
      { message: string; statusCode: number; data: ServiceFee },
      serviceBody
    >({
      query: (body) => ({
        url: END_POINTS.add_service,
        method: "POST",
        body,
      }),
    }),
    addServiceList: builder.mutation<
      {
        message: string;
        statusCode: number;
        data: { serviceName: string; serviceName_ar: string };
      },
      { serviceName: string; serviceName_ar: string }
    >({
      query: (body) => ({
        url: END_POINTS.createUserService,
        method: "POST",
        body,
      }),
    }),
    updateServiceList: builder.mutation<
      {
        message: string;
        statusCode: number;
        data: { serviceName: string; serviceName_ar: string };
      },
      {
        service_id: string;
        body: { serviceName: string; serviceName_ar: string };
      }
    >({
      query: ({ service_id, body }) => ({
        url: `${END_POINTS.updateUserService}/${service_id}`,
        method: "PUT",
        body,
      }),
    }),
    changeServiceListStatus: builder.mutation<
      getServiceFeeRes,
      { service_id: string; body: { isBlocked: boolean } }
    >({
      query: ({ service_id, body }) => ({
        url: `${END_POINTS.updateUserService}/${service_id}`,
        method: "PUT",
        body,
      }),
    }),
    deleteServiceListById: builder.query<
      getServiceList,
      { service_id: string }
    >({
      query: ({ service_id }) => ({
        url: `${END_POINTS.deleteUserService}/${service_id}`,
        method: "DELETE",
      }),
    }),
    getServiceMng: builder.query<ServiceRequestRes, GetTokenParams>({
      query: ({ status, size, page, query }) => ({
        url:
          END_POINTS.getServiceMgmt +
          "?status=" +
          status +
          "&page=" +
          page +
          "&limit=" +
          size +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    updateServiceMgmt: builder.mutation<ServiceRequestRes, ServiceMgmtBody>({
      query: (body) => ({
        url: END_POINTS.update_service,
        method: "PUT",
        body,
      }),
    }),
    getRequestById: builder.query<
      { message: string; statusCode: number; data: ServiceRequest[] },
      { request_id: string }
    >({
      query: ({ request_id }) => ({
        url: `${END_POINTS.getServiceMgmt}/${request_id}`,
        method: "GET",
      }),
    }),
    getServiceMgmtCsv: builder.query<
      {
        statusCode: number;
        message: number;
        data: string;
      },
      unknown
    >({
      query: () => ({
        url: END_POINTS.service_csv,
        method: "GET",
      }),
    }),
    getBanner: builder.query<BannerRes, GetTokenParams>({
      query: ({ size, page, query }) => ({
        url:
          END_POINTS.add_banner +
          "?page=" +
          page +
          "&size=" +
          size +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    deleteBannerId: builder.query<BannerRes, { banner_id: string }>({
      query: ({ banner_id }) => ({
        url: `${END_POINTS.add_banner}/${banner_id}`,
        method: "DELETE",
      }),
    }),
    getCarPlate: builder.query<CarPlateList, { type: string; search: string }>({
      query: ({ type, search }) => ({
        url: END_POINTS.get_car_plate + "?type=" + type + "&search=" + search,
        method: "GET",
      }),
    }),
    addBanner: builder.mutation<
      { message: string; statusCode: number; data: AddBannerRes },
      AddBannerBody
    >({
      query: (body) => ({
        url: END_POINTS.add_banner,
        method: "POST",
        body,
      }),
    }),

    getBannerById: builder.query<
      {
        message: string;
        statusCode: number;
        data: AddBannerRes[];
      },
      { banner_id: string }
    >({
      query: ({ banner_id }) => ({
        url: `${END_POINTS.add_banner}/${banner_id}`,
        method: "GET",
      }),
    }),
    updateBanner: builder.mutation<
      { message: string; statusCode: number; data: AddBannerRes },
      { banner_id: string; body: AddBannerBody }
    >({
      query: ({ banner_id, body }) => ({
        url: `${END_POINTS.add_banner}/${banner_id}/`,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetServiceFeeQuery,
  useChangeServiceFeeStatusMutation,
  useLazyDeleteServiceByIdQuery,
  useLazyGetServiceByIdQuery,
  useUpdateServiceMutation,
  useAddServiceMutation,
  useLazyGetServiceMngQuery,
  useLazyGetRequestByIdQuery,
  useLazyGetServiceMgmtCsvQuery,
  useUpdateServiceMgmtMutation,
  useLazyGetBannerQuery,
  useLazyDeleteBannerIdQuery,
  useLazyGetCarPlateQuery,
  useAddBannerMutation,
  useLazyGetBannerByIdQuery,
  useUpdateBannerMutation,
  useAddServiceListMutation,
  useUpdateServiceListMutation,
  useLazyGetServiceListQuery,
  useLazyGetServiceListByIdQuery,
  useLazyDeleteServiceListByIdQuery,
  useChangeServiceListStatusMutation,
} = serviceApi;
