import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import * as Yup from "yup";
import { useFormik } from "formik";
import { Loader, showError, showToast } from "../../constants";
import { isString } from "../../utils/validations";

import {
  useAddServiceListMutation,
  useLazyGetServiceListByIdQuery,
  useUpdateServiceListMutation,
} from "../../services/service";

const AddServiceList = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [ServiceByIdMutation] = useLazyGetServiceListByIdQuery();
  const [addService, { isLoading }] = useAddServiceListMutation();
  const [updateService, updateServiceData] = useUpdateServiceListMutation();

  //formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      serviceName: "",
      serviceNameAr: "",
    },
    validationSchema: Yup.object({
      serviceName: Yup.string()
        .required("Service Name (english) is required")
        .max(80, "Maximum 80 character are allowed")
        .min(3, "Minimum 2 characters are required"),
      serviceNameAr: Yup.string()
        .required("Service Name (arabic) is required")
        .max(80, "Maximum 80 character are allowed")
        .min(3, "Minimum 2 characters are required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      const body = {
        serviceName: formik.values.serviceName,
        serviceName_ar: formik.values.serviceNameAr,
      };

      if (id) {
        try {
          const response = await updateService({
            service_id: id,
            body,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast(response?.message || "");
            formik.resetForm();
            navigate("/manage-revenue", { state: "service" });
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      } else {
        try {
          const response = await addService(body).unwrap();
          if (response?.statusCode === 200) {
            showToast(response?.message);
            formik.resetForm();
            navigate("/manage-revenue", { state: "service" });
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  const getServiceDetail = async (id: string) => {
    try {
      const response = await ServiceByIdMutation({ service_id: id }).unwrap();
      if (response?.statusCode === 200) {
        formik.setFieldValue("serviceName", response?.data?.serviceName);
        formik.setFieldValue("serviceNameAr", response?.data?.serviceName_ar);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id) {
      getServiceDetail(id);
    }
  }, [id]);

  return (
    <>
      <Loader isLoad={isLoading || updateServiceData?.isLoading} />
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>{id ? "Edit Service" : "Add Service"}</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-revenue", { state: "service" });
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      Service Name (English)
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="serviceName"
                      variant="outlined"
                      fullWidth
                      placeholder="Service Name (english)"
                      id="serviceName"
                      onBlur={formik.handleBlur}
                      value={formik.values.serviceName}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.serviceName && formik.errors.serviceName
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      Service Name (Arabic)
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="serviceNameAr"
                      variant="outlined"
                      fullWidth
                      placeholder="Service Name (arabic)"
                      id="serviceNameAr"
                      onBlur={formik.handleBlur}
                      value={formik.values.serviceNameAr}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.serviceNameAr &&
                        formik.errors.serviceNameAr
                      }
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <div className="form_btn">
                <Button size="large" type="submit" className="btn btn_primary">
                  Save
                </Button>
              </div>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddServiceList;
