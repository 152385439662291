import { END_POINTS } from "../constants/url";
import emptySplitApi from "../utils/rtk";

type GetCmsResponse = {
  statusCode: number;
  message: string;
  data: {
    createdAt: string;
    email: string;
    isDeleted: boolean;
    legal: string;
    legal_ar: string;
    phone: string;
    countryCode: string;
    privacyPolicy: string;
    privacyPolicy_ar: string;
    support: string;
    updatedAt: string;
    _id: string;
  };
};

type PostCmsResponse = {
  message: string;
  statusCode: number;
  data: {
    createdAt: string;
    email: string;
    isDeleted: boolean;
    legal: string;
    legal_ar: string;
    phone: string;
    privacyPolicy: string;
    privacyPolicy_ar: string;
    support: string;
    updatedAt: string;
    _id: string;
  }[];
};

type PostCmsBody = {
  email: string;
  phone: string;
  privacyPolicy: string;
  legal: string;
};

export const cmsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCms: builder.query<GetCmsResponse, {}>({
      query: () => ({
        url: END_POINTS.get_cms,
        method: "GET",
      }),
    }),
    postCms: builder.mutation<
      PostCmsResponse,
      { cms_id: string; body: PostCmsBody }
    >({
      query: ({ cms_id, body }) => ({
        url: `${END_POINTS.get_cms}/${cms_id}`,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const { useLazyGetCmsQuery, usePostCmsMutation } = cmsApi;
