import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  Modal,
  NativeSelect,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
  useAddAdvertisementAmtMutation,
  useLazyDeleteSubscriptionByIdQuery,
  useLazyGetAllAdvertisementQuery,
  useLazyGetAllSubscriptionQuery,
} from "../../services/main";
import { showError, showToast } from "../../constants";
import {
  Advertisement,
  ServiceFee,
  ServiceList,
  Subscription,
} from "../../types/General";
import { useChangeSubscriptionStatusMutation } from "../../services/main";
import { Permissions } from "../../types/User";
import useAuth from "../../hooks/useAuth";
import { generateResponsiveStyle } from "../../utils/ModalStyle";
import { Pagination, SearchBar, WarnModal } from "../../components";
import {
  useAddServiceMutation,
  useChangeServiceFeeStatusMutation,
  useChangeServiceListStatusMutation,
  useLazyDeleteServiceByIdQuery,
  useLazyDeleteServiceListByIdQuery,
  useLazyGetServiceFeeQuery,
  useLazyGetServiceListQuery,
  useUpdateServiceMutation,
} from "../../services/service";
import { isNumber, isString, isValidInput } from "../../utils/validations";
import { Visibility } from "@mui/icons-material";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageRevenue = () => {
  const userData = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const style = generateResponsiveStyle();
  const [deleteSubscriptionById] = useLazyDeleteSubscriptionByIdQuery();
  const [subscriptionStatus] = useChangeSubscriptionStatusMutation();
  const [serviceFeeStatus] = useChangeServiceFeeStatusMutation();
  const [getSubscription] = useLazyGetAllSubscriptionQuery();
  const [ServiceFee] = useLazyGetServiceFeeQuery();
  const [deleteService] = useLazyDeleteServiceByIdQuery();

  const [updateService] = useUpdateServiceMutation();
  const [addService] = useAddServiceMutation();
  const [getAdvertisement] = useLazyGetAllAdvertisementQuery();

  //
  const [serviceList, setServiceList] = useState<ServiceList[]>([]);
  const [getserviceList] = useLazyGetServiceListQuery();
  const [deleteServiceList] = useLazyDeleteServiceListByIdQuery();
  const [changeServiceListStatus] = useChangeServiceListStatusMutation();

  const [page, setPage] = useState(1);
  const [addAdvertisementPrice] = useAddAdvertisementAmtMutation();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [Editopen, setEditOpen] = useState(false);
  const [value, setValue] = useState(
    state === "service" ? 4 : state === "sub" ? 2 : state === "ser" ? 1 : 0
  );
  const [selectedId, setSelectedId] = useState<string>("");
  const [serviceFee, setServiceFee] = useState<ServiceFee[]>([]);
  const [subscription, setSubscription] = useState<Subscription[]>([]);
  const [advertisement, setAdvertisement] = useState<Advertisement[]>([]);
  const [serviceId, setServiceId] = useState<string>("");
  const [advertisementAmt, setAdvertisementAmt] = useState<any>(
    userData?.advertisementAmount || ""
  );
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  useEffect(() => {
    setAdvertisementAmt(userData?.advertisementAmount);
  }, [userData]);

  //formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      serviceType: "",
      serviceFee: "",
    },
    validationSchema: Yup.object({
      serviceType: Yup.string()
        .required("Service Type is required")
        .max(80, "Maximum 80 character are allowed")
        .min(3, "Minimum 2 characters are required"),
      serviceFee: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 digits are required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      const body = {
        serviceType: formik.values.serviceType,
        serviceFees: formik.values.serviceFee,
      };

      if (serviceId) {
        try {
          const response = await updateService({
            service_id: serviceId,
            body,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast(response?.message || "");
            formik.resetForm();
            setEditOpen(false);
            getServiceFee();
            setServiceId("");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      } else {
        try {
          const response = await addService(body).unwrap();
          if (response?.statusCode === 200) {
            showToast(response?.message);
            formik.resetForm();
            setEditOpen(false);
            getServiceFee();
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getAllSubscription = async () => {
    try {
      const response = await getSubscription({}).unwrap();
      if (response?.statusCode === 200) {
        setSubscription(response?.data || []);
      } else {
        setSubscription([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const [totalCount, setTotalCount] = useState<number>(0);
  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getAllAdvertisement = async () => {
    try {
      const response = await getAdvertisement({
        size: 10,
        page: page,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setAdvertisement(response?.data?.advertisementPost || []);
        setTotalCount(response?.data?.count);
      } else {
        setAdvertisement([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleAddPrice = async () => {
    try {
      const response = await addAdvertisementPrice({
        advertisementAmount: Number(advertisementAmt),
      }).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message);
      } else {
        showError(response?.message || "");
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const checkPermission = () => {
    const permission = userData?.permission;

    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Revenue"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  const getServiceFee = async () => {
    try {
      const response = await ServiceFee({
        page: 1,
        size: 10,
        query: "",
      }).unwrap();
      if (response?.statusCode === 200) {
        setServiceFee(response?.data);
        // setTotalCount(response?.data?.userCount);
      } else {
        // setNotification([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const fetchServiceList = async () => {
    try {
      const response = await getserviceList({
        page: 1,
        size: 10,
        query: "",
      }).unwrap();
      if (response?.statusCode === 200) {
        setServiceList(response?.data?.data);
      } else {
        // setNotification([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  useEffect(() => {
    getAllSubscription();
    getServiceFee();
    fetchServiceList();
  }, []);

  useEffect(() => {
    getAllAdvertisement();
  }, [debouncedSearchTerm, page]);

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);

  function createData(
    invoice: string,
    DateTime: string,
    PaymentMethod: string,
    TotalPayments: string
  ) {
    return { invoice, DateTime, PaymentMethod, TotalPayments };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData("#00001", "25-12-2023 / 11:00a.m", "Card", "600 SAR"),
  ];

  const label1 = { inputProps: { "aria-label": "Switch demo" } };

  const handleDeleteSubsciption = async (id: string) => {
    try {
      const response = await deleteSubscriptionById({
        subscription_id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message || "");
        const res = await getSubscription({}).unwrap();
        if (res?.statusCode === 200) {
          setSubscription(res?.data || []);
        } else {
          setSubscription([]);
        }
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleServiceFeeDelete = async (id: string) => {
    try {
      const response = await deleteService({ service_id: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message || "");
        try {
          const response = await ServiceFee({}).unwrap();
          if (response?.statusCode === 200) {
            setServiceFee(response?.data);
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const handleServiceListDelete = async (id: string) => {
    try {
      const response = await deleteServiceList({ service_id: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message || "");
        fetchServiceList();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const updateStatus = async (id: string) => {
    try {
      const response = await subscriptionStatus({ sub_id: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Status updated successfully");
        const res = await getSubscription({}).unwrap();
        if (res?.statusCode === 200 && res?.data?.length) {
          setSubscription(res?.data || []);
        } else {
          setSubscription([]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateServiceFeeStatus = async (id: string) => {
    try {
      const response = await serviceFeeStatus({ service_id: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Status updated successfully");
        const res = await ServiceFee({}).unwrap();
        if (res?.statusCode === 200) {
          setServiceFee(res?.data || []);
        } else {
          setServiceFee([]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateServiceListStatus = async (id: string, status: boolean) => {
    const body = {
      isBlocked: !status,
    };
    try {
      const response = await changeServiceListStatus({
        service_id: id,
        body,
      }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Status updated successfully");
        fetchServiceList();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Manage Revenue</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Manage Payments" {...a11yProps(0)} />
              <Tab label="Manage Service Fee" {...a11yProps(1)} />
              <Tab label="Manage Subscription" {...a11yProps(2)} />
              <Tab label="Manage Advertisement" {...a11yProps(3)} />
              <Tab label="Manage Services" {...a11yProps(4)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <Box className="cards_header">{/* <SearchBar /> */}</Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Invoice no</TableCell>
                      <TableCell>Buyer Details</TableCell>
                      <TableCell>Seller Details</TableCell>
                      <TableCell>Date/Time</TableCell>
                      <TableCell>Payment Method</TableCell>
                      <TableCell>Total Payments</TableCell>
                      <TableCell>Payments Status</TableCell>
                      <TableCell>Buyer Transfer Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, i) => (
                      <TableRow>
                        <TableCell align="center">{i + 1}</TableCell>
                        <TableCell>{row.invoice}</TableCell>
                        <TableCell>
                          Abdul Hussian <br />
                          abdulhussian67@gmail.com <br />
                          +971056734321
                        </TableCell>
                        <TableCell>
                          Seller name <br />
                          seller@gmail.com <br />
                          +971056734321
                        </TableCell>
                        <TableCell>{row.DateTime}</TableCell>
                        <TableCell>{row.PaymentMethod}</TableCell>
                        <TableCell>{row.TotalPayments}</TableCell>
                        <TableCell>
                          <FormControl>
                            <NativeSelect
                              defaultValue={10}
                              variant="outlined"
                              inputProps={{
                                name: "report",
                                id: "uncontrolled-native",
                              }}
                            >
                              <option value={0}>Select</option>
                              <option value={10}>Pending</option>
                              <option value={20}>Confirmed</option>
                            </NativeSelect>
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <FormControl>
                            <NativeSelect
                              defaultValue={10}
                              variant="outlined"
                              inputProps={{
                                name: "report",
                                id: "uncontrolled-native",
                              }}
                            >
                              <option value={0}>Select</option>
                              <option value={10}>Pending</option>
                              <option value={20}>Confirmed</option>
                            </NativeSelect>
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            <Tooltip title="Delete">
                              <IconButton>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
              <Box className="cards_header2">
                {/* <SearchBar /> */}
                <Box className="cards_header_right">
                  {hidePermission?.isAdd || userData?.role === "ADMIN" ? (
                    <Button
                      className="btn btn_primary"
                      // onClick={() => setEditOpen(true)}
                      onClick={() => navigate("/manage-revenue/add-service")}
                    >
                      Add Service
                    </Button>
                  ) : null}
                </Box>
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell align="center">
                        Service Type (English)
                      </TableCell>
                      <TableCell align="center">
                        Service Type (Arabic)
                      </TableCell>

                      <TableCell align="center">Service Fees</TableCell>
                      <TableCell align="center">Service Status</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  {serviceFee?.length ? (
                    serviceFee?.map((item, i) => {
                      return (
                        <TableBody key={item?._id}>
                          <TableRow>
                            <TableCell align="center">{i + 1}</TableCell>
                            <TableCell align="center">
                              {item?.serviceType || "--"}
                            </TableCell>
                            <TableCell align="center">
                              {item?.serviceType_ar || "--"}
                            </TableCell>
                            <TableCell align="center">
                              {item?.serviceFees || 0} Sar
                            </TableCell>
                            <TableCell align="center">
                              <Switch
                                {...label}
                                size="small"
                                checked={!item?.isBlocked}
                                onChange={() =>
                                  updateServiceFeeStatus(item?._id)
                                }
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Box className="table_actions">
                                {hidePermission?.isAdd ||
                                userData?.role === "ADMIN" ? (
                                  <Tooltip title="Edit">
                                    <IconButton
                                      onClick={() =>
                                        navigate(
                                          `/manage-revenue/edit-service/${item?._id}`
                                        )
                                      }
                                      // onClick={() => {
                                      //   setEditOpen(true);
                                      //   setServiceId(item?._id);
                                      // }}
                                    >
                                      <ModeEditIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : null}

                                {hidePermission?.isDelete ||
                                userData?.role === "ADMIN" ? (
                                  <Tooltip title="Delete">
                                    <IconButton
                                      onClick={() => {
                                        setOpen(true);
                                        setSelectedId(item?._id);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : null}
                              </Box>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })
                  ) : (
                    <TableBody>
                      <TableCell align="center" colSpan={5}>
                        No Data Found
                      </TableCell>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Box className="cards_header2">
                {/* <SearchBar /> */}
                <Box className="cards_header_right">
                  {hidePermission?.isAdd || userData?.role === "ADMIN" ? (
                    <Button
                      className="btn btn_primary"
                      onClick={() =>
                        navigate("/manage-revenue/add-subscription")
                      }
                    >
                      Add Subscription
                    </Button>
                  ) : null}
                </Box>
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Image</TableCell>
                      <TableCell>Name (English)</TableCell>
                      <TableCell>Name (Arabic)</TableCell>

                      <TableCell>Description (English)</TableCell>
                      <TableCell>Description (Arabic)</TableCell>

                      <TableCell>1 Month</TableCell>
                      <TableCell>6 Months</TableCell>
                      <TableCell>1 Year</TableCell>
                      {/* <TableCell>Status</TableCell> */}
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  {subscription?.length ? (
                    subscription?.map((row, i) => {
                      return (
                        <TableBody key={row?._id}>
                          <TableRow>
                            <TableCell align="center">{i + 1}</TableCell>
                            <TableCell>
                              <figure className="user_img">
                                <img
                                  src={
                                    row?.image
                                      ? row?.image
                                      : "/static/images/user_placeholder.png"
                                  }
                                  alt=""
                                />
                              </figure>
                            </TableCell>
                            <TableCell>{row?.name || "--"}</TableCell>
                            <TableCell>{row?.name_ar || "--"}</TableCell>

                            <TableCell>
                              {row?.subscriptionDescription || "--"}
                            </TableCell>
                            <TableCell>
                              {row?.subscriptionDescription_ar || "--"}
                            </TableCell>
                            <TableCell>{row?.oneMonth || "--"} SAR</TableCell>
                            <TableCell>{row?.sixMonth || "--"} SAR</TableCell>
                            <TableCell>{row?.oneYear || "--"} SAR</TableCell>
                            {/* <TableCell>
                              <Switch
                                {...label1}
                                size="small"
                                checked={!row?.isBlocked}
                                onChange={() => updateStatus(row?._id)}
                              />
                            </TableCell> */}
                            <TableCell>
                              <Box className="table_actions">
                                {hidePermission?.isAdd ||
                                userData?.role === "ADMIN" ? (
                                  <Tooltip title="Edit">
                                    <IconButton
                                      onClick={() =>
                                        navigate(
                                          `/manage-revenue/edit-subscription/${row?._id}`
                                        )
                                      }
                                    >
                                      <ModeEditIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : null}

                                {hidePermission?.isDelete ||
                                userData?.role === "ADMIN" ? (
                                  <Tooltip title="Delete">
                                    <IconButton
                                      onClick={() => {
                                        setOpen(true);
                                        setSelectedId(row?._id);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : null}
                              </Box>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })
                  ) : (
                    <TableBody>
                      <TableCell align="center" colSpan={9}>
                        No Subscription Found
                      </TableCell>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <Box className="cards_header">
                <Box className="flexad">
                  <div>
                    <Typography className="custom_label">
                      Add Advertisement Price
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"email"}
                      name="email"
                      variant="outlined"
                      fullWidth
                      placeholder="Amount"
                      id="email"
                      style={{ width: 250 }}
                      inputProps={{ maxLength: 10 }}
                      value={advertisementAmt}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          setAdvertisementAmt(val.target.value);
                        }
                      }}
                    />
                  </div>
                  <Button
                    disabled={!advertisementAmt}
                    className="btn btn_primary"
                    onClick={handleAddPrice}
                  >
                    Add
                  </Button>
                </Box>
                <SearchBar
                  value={searchTerm}
                  searchTerm={searchTerm}
                  onCross={() => setSearchTerm("")}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Post Title</TableCell>
                      <TableCell>Car Plate Number</TableCell>
                      <TableCell>Customer Details</TableCell>
                      <TableCell>Total Payments</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  {advertisement?.length ? (
                    advertisement?.map((row, i) => {
                      return (
                        <TableBody key={row?._id}>
                          <TableRow>
                            <TableCell align="center">{i + 1}</TableCell>
                            <TableCell>
                              {row?.postDetails?.posttitle || "--"}
                            </TableCell>
                            <TableCell>
                              {row?.postDetails?.carPlatenumber || "--"}
                            </TableCell>
                            <TableCell>
                              {row?.userDetails?.fullName || "--"} <br />
                              {row?.userDetails?.email || "--"} <br />
                            </TableCell>
                            <TableCell>{row?.amount || "0"} SAR</TableCell>
                            <TableCell>
                              <Box className="table_actions">
                                <Tooltip title="View">
                                  <IconButton
                                    onClick={() =>
                                      navigate(
                                        `/view-advertisement/${row?._id}`
                                      )
                                    }
                                  >
                                    <Visibility />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })
                  ) : (
                    <TableBody>
                      <TableCell align="center" colSpan={6}>
                        No Advertisement Found
                      </TableCell>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <Box className="cards_header2">
                {/* <SearchBar /> */}
                <Box className="cards_header_right">
                  {hidePermission?.isAdd || userData?.role === "ADMIN" ? (
                    <Button
                      className="btn btn_primary"
                      onClick={() => navigate("/manage-revenue/add-services")}
                    >
                      Add Service
                    </Button>
                  ) : null}
                </Box>
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell align="center">
                        Service Name (English)
                      </TableCell>
                      <TableCell align="center">
                        Service Name (Arabic)
                      </TableCell>

                      <TableCell align="center">Service Status</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  {serviceList?.length ? (
                    serviceList?.map((item, i) => {
                      return (
                        <TableBody key={item?._id}>
                          <TableRow>
                            <TableCell align="center">{i + 1}</TableCell>
                            <TableCell align="center">
                              {item?.serviceName || "--"}
                            </TableCell>
                            <TableCell align="center">
                              {item?.serviceName_ar || "--"}
                            </TableCell>

                            <TableCell align="center">
                              <Switch
                                {...label}
                                size="small"
                                checked={!item?.isBlocked}
                                onChange={() =>
                                  // updateServiceFeeStatus(item?._id)
                                  updateServiceListStatus(
                                    item?._id,
                                    item?.isBlocked
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Box className="table_actions">
                                {hidePermission?.isAdd ||
                                userData?.role === "ADMIN" ? (
                                  <Tooltip title="Edit">
                                    <IconButton
                                      onClick={() =>
                                        navigate(
                                          `/manage-revenue/edit-services/${item?._id}`
                                        )
                                      }
                                    >
                                      <ModeEditIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : null}

                                {hidePermission?.isDelete ||
                                userData?.role === "ADMIN" ? (
                                  <Tooltip title="Delete">
                                    <IconButton
                                      onClick={() => {
                                        setOpen(true);
                                        setSelectedId(item?._id);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : null}
                              </Box>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })
                  ) : (
                    <TableBody>
                      <TableCell align="center" colSpan={5}>
                        No Data Found
                      </TableCell>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => {
          if (value === 2) {
            handleDeleteSubsciption(selectedId);
          } else if (value === 1) {
            handleServiceFeeDelete(selectedId);
          } else if (value === 4) {
            handleServiceListDelete(selectedId);
          }
        }}
        // handleDelete={() => handleDeleteSubsciption(selectedId)}
        name={value === 2 ? "subscription" : "service"}
      />
      <Modal
        open={Editopen}
        onClose={() => setEditOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className="custom_label">Service Type</Typography>
                <TextField
                  className="text_field"
                  hiddenLabel
                  type={"text"}
                  name="serviceType"
                  variant="outlined"
                  fullWidth
                  placeholder="Service Type"
                  id="serviceType"
                  onBlur={formik.handleBlur}
                  value={formik.values.serviceType}
                  onChange={(val) => {
                    if (val.target.value === " " || val.target.value === ".") {
                    } else if (isString(val.target.value)) {
                      formik.handleChange(val);
                    }
                  }}
                  helperText={
                    formik.touched.serviceType && formik.errors.serviceType
                  }
                />
                {/* <FormControl fullWidth>
                  <NativeSelect
                    fullWidth
                    defaultValue={0}
                    variant="outlined"
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={0}>Select</option>
                    <option value={10}>Mediator</option>
                    <option value={20}>Transfer Car Plate</option>
                  </NativeSelect>
                </FormControl> */}
              </Grid>
              <Grid item xs={12}>
                <Typography className="custom_label">Service Fees</Typography>
                <TextField
                  className="text_field"
                  hiddenLabel
                  type={"text"}
                  name="serviceFee"
                  variant="outlined"
                  fullWidth
                  placeholder="Fee"
                  id="serviceFee"
                  onBlur={formik.handleBlur}
                  value={formik.values.serviceFee}
                  onChange={(val) => {
                    if (val.target.value === " " || val.target.value === ".") {
                    } else if (isNumber(val.target.value)) {
                      formik.handleChange(val);
                    }
                  }}
                  inputProps={{ maxLength: 20 }}
                  helperText={
                    formik.touched.serviceFee && formik.errors.serviceFee
                  }
                />
              </Grid>
            </Grid>
            <div className="form_btn">
              <Button size="large" type="submit" className="btn btn_primary">
                Save
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
      <Pagination
        setPage={setPage}
        module={advertisement}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
    </MainContainer>
  );
};

export default ManageRevenue;
