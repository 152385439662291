/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { Loader, showError, showToast } from "../../constants";
import { isString } from "../../utils/validations";
import {
  useAddCategoryMutation,
  useLazyGetCateByIdQuery,
  useUpdateCateMutation,
} from "../../services/main";

const AddCategories = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [addCategory, { isLoading }] = useAddCategoryMutation();
  const [updateCategory] = useUpdateCateMutation();
  const [getCateById] = useLazyGetCateByIdQuery();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      name_ar: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Category is required")
        .max(80, "Maximum 80 character are allowed")
        .min(3, "Minimum 2 characters are required"),
      name_ar: Yup.string()
        .required("Arabic category name is required")
        .max(80, "Maximum 80 character are allowed")
        .min(3, "Minimum 2 characters are required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      const body = {
        categoryName: formik.values.name,
        categoryName_ar: formik.values.name_ar,
      };
      if (id && id !== "add") {
        try {
          const response = await updateCategory({
            cate_id: id,
            body,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast(response?.message || "");
            formik.resetForm();
            navigate("/manage-categories");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      } else {
        try {
          const response = await addCategory(body).unwrap();
          if (response?.statusCode === 200) {
            showToast(response?.message);
            formik.resetForm();
            navigate("/manage-categories");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  const getCateDetail = async (id: string) => {
    try {
      const response = await getCateById({ cate_id: id }).unwrap();
      if (response?.statusCode === 200) {
        formik.setFieldValue("name", response?.data?.categoryName);
        formik.setFieldValue("name_ar", response?.data?.categoryName_ar);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getCateDetail(id);
    }
  }, []);

  return (
    <>
      <Loader isLoad={isLoading} />
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>{id ? "Edit Category" : "Add Category"}</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-categories");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Category Name (English)
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="name"
                      variant="outlined"
                      fullWidth
                      placeholder="Name"
                      id="name"
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Category Name (Arabic)
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="name_ar"
                      variant="outlined"
                      fullWidth
                      placeholder="Name"
                      id="name_ar"
                      onBlur={formik.handleBlur}
                      value={formik.values.name_ar}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.name_ar && formik.errors.name_ar
                      }
                    />
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddCategories;
