export const API_URL = "https://lowhateapi.appgrowthcompany.com/api/v1"; //development

export const END_POINTS = {
  login: "/admin/login",
  forgotPassword: "admin//forgotPassword",
  get_user: "/admin/profile",
  update_profile: "admin/updateProfile",
  mediaUpload: "/admin/upload",
  verifyOtp: "/admin/verifyOtp",
  resetPassword: "/admin/setPassword",
  changePassword: "/admin/changePassword",

  //user
  get_all_user: "/admin/user",
  user_by_id: "/admin/userById",
  edit_user_by_id: "/admin/user/",
  delete_user_by_id: "/admin/user",
  user_status: "/admin/userStatus/",
  user_csv: "admin/exportUser",

  //post for sell
  get_all_sell: "/admin/sellPost",
  sell_By_id: "/admin/sellPostById",
  sell_status: "/admin/sellStatus/",
  carPlate_service: "/admin/carPlateService",

  //auction
  get_all_auction: "/admin/auctionPost",
  auction_status: "/admin/auctionStatus/",
  update_auction_status: "/admin/updateAuctionStatus",
  getBiderByAuctionId: "/admin/getBiderByAuctionId",

  //offer
  get_all_offer: "/admin/offerPost",
  get_offer_byId: "admin/getofferPostById/",
  //cms
  get_cms: "/admin/cms",
  get_faq: "/admin/faq",

  //subscribtion
  subscription: "/admin/subscription",
  sub_by_id: "/admin/getSubscriptionById",
  sub_status: "/admin/subscriptionStatus/",

  //subAdmin
  subAdmin: "/admin/subAdmin",
  subAdmin_status: "/admin/subAdminStatus",
  subadmin_by_id: "/admin/subAdminById",

  //category
  category: "/admin/category",
  cate_by_id: "/admin/CategoryById",
  cate_status: "/admin/categoryStatus/",

  //notification
  notification: "/admin/notification",
  dashboard: "/admin/dashboard",
  userNotification: "/admin/userNotification",

  //services
  add_service: "/admin/services",
  getServiceFee: "/admin/getService",
  service_fee_status: "/admin/serviceStatusChange/",
  delete_service: "/admin/deleteService/",
  edit_service: "/admin/updateService/",

  getServiceMgmt: "/admin/getServiceMgmt",
  service_csv: "/admin/exportCsvServiceMgmt",
  update_service: "/admin/updateServiceMgmt",

  //banner
  add_banner: "/admin/banner",
  get_banner_carPlate: "/admin/carPlatenumber",
  get_car_plate: "/admin/carPlatenumber",

  //advertisement
  advertisementPost: "/admin/advertisementPost",

  //serviceList
  createUserService: "/admin/createUserService",
  updateUserService: "/admin/updateUserService",
  deleteUserService: "/admin/deleteUserService",
  getServices: "/admin/getUserService",
};
