export interface AllUser {
  _id: string;
  userName: string;
  email: string;
  phone: string;
  totalsell: number;
  totalauction: number;
  totalpost: number;
  isBlocked?: boolean;
  fullName: string;
  countryCode: string;
  totalearnings: number;
  image: string;
  planPrice: string;
  planName: string;
  typeOfPlan: string;
}

export interface UserSell {
  buyerDetails: {
    countryCode: string;
    email: string;
    fullName: string;
    phone: string;
    userName: string;
    _id: string;
  };
  postDetails: {
    carPlatenumber: string;
    category: string;
    posttitle: string;
    _id: string;
  };
  isWinner: boolean;
  invoiceNo: number;
  createdAt: string;
  auctionDuration: string;
  carPlatenumber: string;
  carType: string;
  categoryData: {
    categoryName: string;
    categoryType: number;
    image: string;
    isBlocked: boolean;
    _id: string;
  };
  image: string;
  isBlocked: boolean;
  meaningOfPlate: string;
  minimumOffer: string;
  name: string;
  numberPlateType: number;
  posttitle: string;
  price: number;
  reservePrice: string;
  role: string;
  startingBid: string;
  status: number;
  subscription: [];
  typeOfPrice: string;
  userId: string;
  _id: string;
  UnderInsurence: boolean;
  carNeedCheck: boolean;
  carPlateRenewed: boolean;
  bidingStartTime: string;
  bidingEndTime: string;
  agentName: string;
  countryCode: string;
  phone: string;
  email: string;
}

export interface Earning {
  addressId: string;
  amount: number;
  category: string;
  createdAt: string;
  invoiceNo: number;
  isBlocked: boolean;
  isDeleted: boolean;
  postId: string;
  sellerId: string;
  serviceFee: number;
  status: number;
  userId: string;
  _id: string;
}

export interface PostSell {
  CustomerDetails: {
    coverImage: string;
    countryCode: string;
    fullName: string;
    image: string;
    phone: string;
    seeTaggedPost: string;
    updatedAt: string;
    userName: string;
    _id: string;
    email: string;
    isBlocked: boolean;
    isDeleted: boolean;
  };
  _id: string;
  carPlatenumber: string;
  category: string;
  posttitle: string;
  typeOfPrice: string;
  price: number;
  isBlocked: boolean;
  image: string;
  createdAt: string;
  userName: string;
  postId: string;
  offerPrice: number;
}

export interface SellById {
  posttitle: string;
  carPlatenumber: string;
  categoryData: {
    categoryName: string;
    _id: string;
  };
  typeOfPrice: string;
  price: number;
  _id: string;
  userId: {
    fullName: string;
    email: string;
    image: string;
    _id: string;
    phone: string;
    countryCode: string;
  };
  serviceId: string[];
}

export interface Subscription {
  currency: string;
  image: string;
  isBlocked: boolean;
  isDeleted: boolean;
  name: string;
  name_ar: string;
  oneMonth: number;
  oneYear: number;
  sixMonth: number;
  subscriptionDescription: string;
  subscriptionDescription_ar: string;
  _id: string;
}

export interface Category {
  categoryName: string;
  categoryName_ar: string;
  categoryType: number;
  // image: string;
  isBlocked: boolean;
  isDeleted: boolean;
  _id: string;
}

export interface Auction {
  carPlatenumber: string;
  categoryData: {
    categoryName: string;
    _id: string;
  };
  category: string;
  email: string;
  image: string;
  phone: string;
  userName: string;
  userfullName: string;
  price: number;
  countryCode: string;
  posttitle: string;
  startingBid: string;
  endingBid: string;
  reservePrice: string;
  minimumOffer: string;
  bidingEndTime: string;
  bidingStartTime: string;
  isBlocked: boolean;
  status: any;
  meaningOfPlate: string;
  _id: string;
  userId: {
    _id: string;
    isBlocked: boolean;
    phone: string;
    email: string;
    fullName: string;
    countryCode: string;
  };
}

export interface Notification {
  Message: string;
  Title: string;
  isBlocked: boolean;
  isDeleted: boolean;
  _id: string;
  createdAt: string;
}

export interface Dashboard {
  userCount: number;
  orderCount: number;
  sellPostCount: number;
  auctionPostCount: number;
  revenueCount: number;
  totalOrders: {};
  totalRevenue: {};
  totalUsers: {};
  sellPost: {};
  auctionPost: {};
}

export interface SubAdmin {
  firstName: string;
  lastName: string;
  fullName: string;
  name: string;
  phone: string;
  isProfileComplete: boolean;
  password: string;
  image: string;
  isBlocked: boolean;
  isDeleted: boolean;
  role: string;
  roleType: string;
  _id: string;
  email: string;
  countryCode: string;
  permission: [
    {
      label: string;
      isView: boolean;
      isAdd: boolean;
      isDelete: boolean;
      _id: string;
    }
  ];
}

export interface Faqs {
  question: string;
  question_ar: string;
  answer: string;
  answer_ar: string;
  isDeleted: boolean;
  _id: string;
}

export interface PostService {
  id: string;
  serviceName: string;
  _id: string;
}

export interface ServiceFee {
  isBlocked: boolean;
  isDeleted: boolean;
  serviceFees: number;
  serviceType: string;
  serviceType_ar: string;
  features: string;
  _id: string;
}
export interface ServiceList {
  isBlocked: boolean;
  isDeleted: boolean;
  serviceName: string;
  serviceName_ar: string;
  _id: string;
}
export interface ServiceRequest {
  createdAt: string;
  isBlocked: boolean;
  isDeleted: boolean;
  serviceFees: number;
  serviceId: string;
  serviceType: string;
  _id: string;
  status: number;
  userId: string;
  price: string | number;
  agentDetails: {
    countryCode: string;
    email: string;
    image: string;
    phone: string;
    _id: string;
    userName: string;
    firstName: string;
    lastName: string;
  };
  userDetails: {
    countryCode: string;
    email: string;
    fullName: string;
    phone: string;
    userName: string;
    _id: string;
    firstName: string;
    lastName: string;
  };
}

export interface BannerResponse {
  _id: string;
  title: string;
  image: string;
  role: string;
  isBlocked: boolean;
  postId: string;
  startDate: string;
  endDate: string;
  postDetails: {
    _id: string;
    role: string;
    posttitle: string;
    meaningOfPlate: string;
    carPlatenumber: string;
    status: number;
  };
}

export interface AddBannerRes {
  title: string;
  image: string | undefined;
  role: string;
  isBlocked: boolean;
  _id: string;
  postId: string;
  startDate: string;
  endDate: string;
}

export interface AuctionBidder {
  createdAt: string;
  fullName: string;
  image: string;
  isWinner: boolean;
  price: number;
  _id: string;
  email: string;
  countryCode: string;
  phone: string;
}

export interface CarPlateRes {
  _id: string;
  role: string;
  carPlatenumber: any;
}
export interface Advertisement {
  amount: number;
  createdAt: string;
  isDeleted: false;
  postId: string;
  postDetails: {
    carPlatenumber: string;
    meaningOfPlate: string;
    posttitle: string;
    role: string;
    status: number;
    _id: string;
  };
  userDetails: {
    email: string;
    fullName: string;
    image: string;
    phone: string;
    userName: string;
    _id: string;
  };
  userId: string;
  __v: number;
  _id: string;
}

export const status = {
  1: "Accepted",
  2: "Ongoing",
  3: "Live",
  4: "Rejected",
  5: "Cancelled",
  6: "Completed",
} as any;

export const ServiceStatus = {
  0: "PENDING",
  1: "ACCEPTED",
  2: "REJECTED",
  3: "PAYMENT COMPLETED",
  4: "APPROVED ",
  5: "COMPLETED",
} as any;

export const PostStatus = [
  { id: 1, name: "ACCEPTED" },
  { id: 4, name: "REJECTED" },
];

export const RequestStatus = [
  { id: 0, name: "PENDING" },
  { id: 1, name: "ACCEPT" },
  { id: 2, name: "REJECT" },
  { id: 3, name: "PAYMENT COMPLETED" },
  { id: 4, name: "APPROVED " },
  { id: 5, name: "COMPLETED" },
];

export const PaymentStatus = [
  { id: 1, name: "PAYMENT PENDING" },
  { id: 3, name: "PAYMENT COMPLETED" },
  { id: 4, name: "APPROVE" },
];
