import { Routes, Route, useNavigate } from "react-router-dom";
import Pages from "./pages";
import UserDetails from "./pages/users/details";
import UsersForm from "./pages/users/form/usersForm";
import { useEffect } from "react";
import { STORAGE_KEYS, getFromStorage } from "./constants";
import { useAppDispatch } from "./hooks/store";
import { resetAuth, setCredentials } from "./reducers/authSlice";

const Routing = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    if (token && window.location.pathname === "/") {
      dispatch(
        setCredentials({
          token: token,
          user: null,
        })
      );
      navigate("/dashboard", { replace: true });
    } else if (!token && window?.location?.pathname === "/") {
      dispatch(resetAuth());
      navigate("/", { replace: true });
    } else if (token) {
      dispatch(
        setCredentials({
          token: token,
          user: null,
        })
      );
      navigate(window?.location?.pathname);
    } else {
      dispatch(resetAuth());
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Pages.LoginPage />} />
      <Route path="/forgotpassword" element={<Pages.ForgotPasswordPage />} />
      <Route path="/changepassword" element={<Pages.ChangePassword />} />
      <Route path="/verifyotp" element={<Pages.VerifyOtp />} />
      <Route path="/resetpassword" element={<Pages.ResetPassword />} />
      <Route path="/dashboard" element={<Pages.DashBoardPage />} />
      <Route path="/profile" element={<Pages.Profile />} />
      <Route path="/manageusers" element={<Pages.ManageUsers />} />
      <Route path="/manageusers/userdetails/:id" element={<UserDetails />} />
      <Route path="/manageusers/usersform/:id" element={<UsersForm />} />
      <Route path="/manageservices" element={<Pages.Services />} />
      <Route
        path="/manageservices/view-orders/:id"
        element={<Pages.OrderDetails />}
      />
      <Route path="/analytics" element={<Pages.Analytics />} />
      <Route path="/faq" element={<Pages.Faq />} />
      <Route path="/cms" element={<Pages.ManageCms />} />
      <Route
        path="/manage-notifications"
        element={<Pages.ManageNotifications />}
      />
      <Route
        path="/manage-notifications/add"
        element={<Pages.AddNotification />}
      />
      <Route
        path="/received-notifications"
        element={<Pages.RecievedNotifications />}
      />
      <Route path="/customer-support" element={<Pages.CustomerSupport />} />
      <Route path="/manage-subAdmin" element={<Pages.ManageSubAdmin />} />
      <Route path="/manage-subAdmin/add" element={<Pages.AddSubAdmin />} />
      <Route path="/manage-subAdmin/edit/:id" element={<Pages.AddSubAdmin />} />
      <Route path="/auction-bidding/:id" element={<Pages.AuctionBids />} />

      <Route path="/manage-categories" element={<Pages.ManageCategories />} />
      <Route path="/manage-categories/add" element={<Pages.AddCategories />} />
      <Route
        path="/manage-categories/edit/:id"
        element={<Pages.AddCategories />}
      />
      <Route path="/manage-banner" element={<Pages.ManageBanner />} />
      <Route path="/manage-banner/add" element={<Pages.AddBanner />} />
      <Route path="/manage-banner/edit/:id" element={<Pages.AddBanner />} />
      <Route path="/manage-revenue" element={<Pages.ManageRevenue />} />
      <Route
        path="/manage-revenue/edit-subscription/:id"
        element={<Pages.AddSubscription />}
      />
      <Route
        path="/manage-revenue/edit-service/:id"
        element={<Pages.AddService />}
      />
      <Route
        path="/manage-revenue/add-services"
        element={<Pages.AddServiceList />}
      />
      <Route
        path="/manage-revenue/edit-services/:id"
        element={<Pages.AddServiceList />}
      />
      <Route
        path="/manage-revenue/add-subscription"
        element={<Pages.AddSubscription />}
      />
      <Route
        path="/manage-revenue/add-service"
        element={<Pages.AddService />}
      />

      <Route path="/manage-posts" element={<Pages.ManagePosts />} />
      <Route
        path="/manage-posts/editSale/:id"
        element={<Pages.AddPostForSell />}
      />
      <Route
        path="/manage-posts/viewSale/:id"
        element={<Pages.ViewPostforSell />}
      />
      <Route
        path="/manage-posts/editAuction/:id"
        element={<Pages.AddAuctionForSell />}
      />
      <Route
        path="/manage-posts/viewAuction/:id"
        element={<Pages.ViewAuctionforSell />}
      />
      <Route path="/chat" element={<Pages.Chat />} />
      <Route path="/view-offer/:id" element={<Pages.ViewOffer />} />
      <Route path="/setting" element={<Pages.Setting />} />
      <Route
        path="/view-advertisement/:id"
        element={<Pages.ViewAdvertisement />}
      />
    </Routes>
  );
};

export default Routing;
