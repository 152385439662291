import { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  useChangeCateStatusMutation,
  useLazyDeleteCateByIdQuery,
  useLazyGetAllCateQuery,
} from "../../services/main";
import {
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Loader, showError, showToast } from "../../constants";
import { Category } from "../../types/General";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";
import { WarnModal } from "../../components";

const ManageCategories = () => {
  const userData = useAuth();
  const navigate = useNavigate();
  const [allCategory, { isLoading }] = useLazyGetAllCateQuery();
  const [deleteCate] = useLazyDeleteCateByIdQuery();
  const [cateStatus] = useChangeCateStatusMutation();

  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [category, setCategory] = useState<Category[]>([]);
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const checkPermission = () => {
    const permission = userData?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Categories"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  const getAllCate = async () => {
    try {
      const response = await allCategory({}).unwrap();
      if (response?.statusCode === 200) {
        setCategory(response?.data);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const updateStatus = async (id: string) => {
    try {
      const response = await cateStatus({ cate_id: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Status updated successfully");
        const res = await allCategory({}).unwrap();
        if (res?.statusCode === 200) {
          setCategory(res?.data || []);
        } else {
          setCategory([]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteCate({ cate_id: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message || "");
        try {
          const response = await allCategory({}).unwrap();
          if (response?.statusCode === 200) {
            setCategory(response?.data);
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);

  useEffect(() => {
    getAllCate();
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Manage Categories</h1>
        </div>
        <Card className="cards">
          {/* <Box className="cards_header2">
            <SearchBar />
            <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-categories/add")}
              >
                Add Category
              </Button>
            </Box>
          </Box> */}
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell align="center">Category Name (English)</TableCell>
                  <TableCell align="center">Category Name (Arabic)</TableCell>
                  <TableCell align="center">Category Type</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              {category?.length ? (
                category?.map((row, i) => {
                  return (
                    <TableBody key={row?._id}>
                      <TableRow>
                        <TableCell align="center">{i + 1}</TableCell>
                        <TableCell align="center">
                          {row?.categoryName || "--"}
                        </TableCell>
                        <TableCell align="center">
                          {row?.categoryName_ar || "--"}
                        </TableCell>
                        <TableCell align="center">
                          {row?.categoryType || "--"}
                        </TableCell>
                        <TableCell align="center">
                          <Switch
                            {...label}
                            size="small"
                            checked={!row?.isBlocked}
                            onChange={() => updateStatus(row?._id)}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Box className="table_actions">
                            {hidePermission?.isAdd ||
                            userData?.role === "ADMIN" ? (
                              <Tooltip title="Edit">
                                <IconButton
                                  onClick={() =>
                                    navigate(
                                      `/manage-categories/edit/${row?._id}`
                                    )
                                  }
                                >
                                  <ModeEditIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                            {/* {hidePermission?.isDelete ||
                            userData?.role === "ADMIN" ? (
                              <Tooltip title="Delete">
                                <IconButton
                                  onClick={() => {
                                    setOpen(true);
                                    setSelectedId(row?._id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null} */}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : (
                <TableBody>
                  <TableCell align="center" colSpan={4}>
                    No Category Found
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="category"
      />
    </MainContainer>
  );
};

export default ManageCategories;
