import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  useAddSubscriptionMutation,
  useLazyGetSubscriptionByIdQuery,
  useUpdateSubscriptionMutation,
} from "../../services/main";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Loader, showError, showToast } from "../../constants";
import { isNumber, isString } from "../../utils/validations";
import { UploadMedia } from "../../utils/mediaUpload";
import { Subscription } from "../../types/General";

const AddSubscription = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [image, setImage] = useState<string | undefined>("");

  const [fileName, setFileName] = useState<string>("");
  const [subscription, setSubscription] = useState<Subscription | null>(null);

  const [addSubscription, { isLoading }] = useAddSubscriptionMutation();
  const [updateSubscription, updateSubscriptionData] =
    useUpdateSubscriptionMutation();
  const [getSubById] = useLazyGetSubscriptionByIdQuery();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: subscription?.name || "",
      nameAr: subscription?.name_ar || "",
      subscriptionDescription: subscription?.subscriptionDescription || "",
      subscriptionDescriptionAr: subscription?.subscriptionDescription_ar || "",
      oneMonth: subscription?.oneMonth || "",
      sixMonth: subscription?.sixMonth || "",
      oneYear: subscription?.oneYear || "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is required")
        .max(80, "Maximum 80 character are allowed")
        .min(3, "Minimum 2 characters are required"),
      nameAr: Yup.string()
        .required("Arabic name is required")
        .max(80, "Maximum 80 character are allowed")
        .min(3, "Minimum 2 characters are required"),
      subscriptionDescription: Yup.string()
        .required("Description is required")
        .max(80, "Maximum 80 character are allowed")
        .min(2, "Minimum 2 characters are required"),
      subscriptionDescriptionAr: Yup.string()
        .required("Arabic description is required")
        .max(80, "Maximum 80 character are allowed")
        .min(2, "Minimum 2 characters are required"),
      oneMonth: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 digits are required")
        .max(10, "Maximum 10 digits are allowed"),

      sixMonth: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 digits are required")
        .max(10, "Maximum 10 digits are allowed"),
      oneYear: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 digits are required")
        .max(10, "Maximum 10 digits are allowed"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      let body = {
        name: formik.values.name,
        name_ar: formik.values.nameAr,
        subscriptionDescription: formik.values.subscriptionDescription,
        subscriptionDescription_ar: formik.values.subscriptionDescriptionAr,
        oneMonth: formik.values.oneMonth,
        sixMonth: formik.values.sixMonth,
        oneYear: formik.values.oneYear,
        image: image,
      };
      if (id && id !== "add-subscription") {
        try {
          const response = await updateSubscription({
            sub_id: id,
            body,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast(response?.message || "");
            navigate("/manage-revenue", { state: "sub" });
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      } else {
        try {
          const response = await addSubscription(body).unwrap();
          if (response?.statusCode === 200) {
            showToast("Subscription Create Successfully");
            formik.resetForm();
            navigate("/manage-revenue", { state: "sub" });
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      setImage(res?.data);
    } else {
      showError(res?.message);
    }
  };

  const getSubscriptionDetail = async (id: string) => {
    try {
      const response = await getSubById({ sub_id: id }).unwrap();
      if (response?.statusCode === 200) {
        setSubscription(response?.data);
        setImage(response?.data?.image);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getSubscriptionDetail(id);
    }
  }, []);

  return (
    <>
      <Loader isLoad={isLoading || updateSubscriptionData?.isLoading} />
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>{id ? "Edit Subscription" : "Add Subscription"}</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-revenue", { state: "sub" });
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">Image</Typography>
                    {image ? (
                      <div className="upload_image_preview">
                        <CardMedia component="img" image={image} alt="photo" />
                        <CancelIcon
                          onClick={() => {
                            setImage("");
                            setFileName("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              src={
                                image
                                  ? image
                                  : "/static/images/user_placeholder.png"
                              }
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Subscription Name (English)
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="name"
                      variant="outlined"
                      fullWidth
                      placeholder="Name (english)"
                      id="name"
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Subscription Name (Arabic)
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="nameAr"
                      variant="outlined"
                      fullWidth
                      placeholder="Name (arabic)"
                      id="nameAr"
                      onBlur={formik.handleBlur}
                      value={formik.values.nameAr}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={formik.touched.nameAr && formik.errors.nameAr}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Typography className="custom_label">
                      Subscription Description (English)
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="subscriptionDescription"
                      variant="outlined"
                      fullWidth
                      placeholder="Description (english)"
                      id="subscriptionDescription"
                      onBlur={formik.handleBlur}
                      value={formik.values.subscriptionDescription}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.subscriptionDescription &&
                        formik.errors.subscriptionDescription
                      }
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Typography className="custom_label">
                      Subscription Description (Arabic)
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="subscriptionDescriptionAr"
                      variant="outlined"
                      fullWidth
                      placeholder="Description (arabic)"
                      id="subscriptionDescriptionAr"
                      onBlur={formik.handleBlur}
                      value={formik.values.subscriptionDescriptionAr}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.subscriptionDescriptionAr &&
                        formik.errors.subscriptionDescriptionAr
                      }
                    />
                  </Grid>
                  <Grid item xs={4} />

                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      1 Month Price
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="oneMonth"
                      variant="outlined"
                      fullWidth
                      placeholder="10 SAR"
                      id="oneMonth"
                      onBlur={formik.handleBlur}
                      value={formik.values.oneMonth}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      inputProps={{ maxLength: 20 }}
                      helperText={
                        formik.touched.oneMonth && formik.errors.oneMonth
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      6 Month Price
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="sixMonth"
                      variant="outlined"
                      fullWidth
                      placeholder="10 SAR"
                      id="sixMonth"
                      onBlur={formik.handleBlur}
                      value={formik.values.sixMonth}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      inputProps={{ maxLength: 20 }}
                      helperText={
                        formik.touched.sixMonth && formik.errors.sixMonth
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      1 Year Price
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="oneYear"
                      variant="outlined"
                      fullWidth
                      placeholder="10 SAR"
                      id="oneYear"
                      onBlur={formik.handleBlur}
                      value={formik.values.oneYear}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      inputProps={{ maxLength: 20 }}
                      helperText={
                        formik.touched.oneYear && formik.errors.oneYear
                      }
                    />
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddSubscription;
