import LoginPage from "./auth/login";
import DashBoardPage from "./dashBoard";
import ManageUsers from "./users";
import ForgotPasswordPage from "./auth/forgotPassword";
import VerifyOtp from "./auth/otp";
import Services from "./services";
import Analytics from "./analytics";
import Faq from "./faq";
import ResetPassword from "./auth/resetPassword";
import Profile from "./profile";
import ManageCms from "./manageCms";
import OrderDetails from "./services/view-order";
import ManageNotifications from "./manageNotification";
import AddNotification from "./manageNotification/add";
import CustomerSupport from "./customerSupport";
import ManageSubAdmin from "./manageSubAdmin";
import AddSubAdmin from "./manageSubAdmin/add";
import ManageCategories from "./manageCategories";
import AddCategories from "./manageCategories/add";
import ManageRevenue from "./manageRevenue";
import AddSubscription from "./manageRevenue/addSubscription";
import ManagePosts from "./managePosts";
import ViewPostforSell from "./managePosts/viewPostForSell";
import ViewAuctionforSell from "./managePosts/viewAuctionForSell";
import AddPostForSell from "./managePosts/addPostForSell";
import AddAuctionForSell from "./managePosts/addAuctionForSell";
import RecievedNotifications from "./manageNotification/receivedNotification";
import Chat from "./chat";
import ChangePassword from "./auth/changePassword";
import AddBanner from "./manageBanner/add";
import ManageBanner from "./manageBanner";
import AuctionBids from "./managePosts/AuctionBids";
import ViewOffer from "./managePosts/viewOffer";
import ViewAdvertisement from "./manageRevenue/viewAdvertisement";
import AddService from "./manageRevenue/addService";
import Setting from "./setting";
import AddServiceList from "./manageRevenue/addServiceList";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Setting,
  AddService,
  LoginPage,
  DashBoardPage,
  ManageUsers,
  ForgotPasswordPage,
  VerifyOtp,
  Services,
  Analytics,
  Faq,
  ResetPassword,
  Profile,
  ManageCms,
  OrderDetails,
  ManageNotifications,
  AddNotification,
  CustomerSupport,
  ManageSubAdmin,
  AddSubAdmin,
  ManageCategories,
  AddCategories,
  ManageRevenue,
  AddSubscription,
  ManagePosts,
  ViewPostforSell,
  ViewAuctionforSell,
  AddPostForSell,
  AddAuctionForSell,
  RecievedNotifications,
  Chat,
  ChangePassword,
  ManageBanner,
  AddBanner,
  AuctionBids,
  ViewOffer,
  ViewAdvertisement,
  AddServiceList,
};
